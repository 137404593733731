.btnMore{
    width: 220px;
    height: 100px;
    border-radius: 25px;
}
.btnMinus{
    width: 110px;
    height: 50px;
    border-radius: 25px;
    background-color: rgba(8, 17, 136, 0.342);
    font-size: 0.8rem;
    padding: 4px;
    border: 2px solid #10d9e7;
    color: #fff;
}
