body {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    background-color: #c9f8f93f;
}
.center{
    margin-left: 190px !important;
    width: 80% !important;   
}


.logo {
    width: 105px;
    height: 55px;
    border-radius: 2px;
}

nav {
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 71px;
    bottom: 0;
    width: 120px;
    padding: 20px;
    bottom: 0;
    left: 0;
    z-index: 1;
    overflow-y: auto;
  }
  

nav a {
    color: #000;
    text-decoration: none;
    margin-bottom: 10px;
    font-size: 16px;
}

.btnLogout {
    border: none !important;
    background-color: transparent;
}

.btnLogout:hover {
    font-weight: bold !important;
}

h1 {
    font-size: 22px;
}

.main-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    /* margin: -10px; para compensar o espaçamento padrão das colunas */
    justify-content: space-around;
}

.col-4 {
    flex: 0 0 30%;
    max-width: 30%;
    padding: 10px;
    min-width: 240px;
    margin-bottom: 10px;
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 10px;
    min-width: 240px;
    margin-bottom: 10px;
}

.col-A {
    border: 1px solid gray;
    border-radius: 5px;
    padding: 10px;
    margin-right: 5px;
    min-width: 240px;
    margin-bottom: 10px;
}

/* Para a última coluna da primeira linha, remova a margem à direita */
.row .col-4:last-child {
    margin-right: 0;
}

.text-center {
    text-align: center;
}

.form-group {
    background-color: rgba(0, 0, 255, 0.256);
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid rgb(4, 4, 95);

}

.form-label {
    font-size: 11px;
    margin-top: 12px;
}

.form-control {
    width: 210px;
    height: 10px;
    font-size: 13px;
    background-color: rgba(245, 238, 247, 0.901);
}

.form-btn {
    margin-top: 15px;
}

.readOnly {
    font-weight: bold;
}

.message {
    font-weight: bold;
    color: red;
}
