.videoBan1{
    margin-top: 90px;
    width: 100vw;
    height: 100vh;
    background-image: url('/img/teamworking.jpg');
    background-attachment: fixed;
  }
  
  .videoBan2{
    margin-top: 90px;
    width: 100vw;
    height: 100vh;
    /* background-attachment: fixed; */
  }
  .videoService{
    position: relative;
    width: 100%;
    margin-top: -400px;
    z-index: 1;
    border-radius: 15px !important;
  }
  .slick-slider{
    display: flex;
    width: 100vw;
    height: 400px;
    background-color: rgba(153, 205, 50, 0.226);
    /* border-radius: 15px; */
    /* overflow: hidden; */
  }
  
  .slick-initialized{
    background-color: #06283854;
    background-image: url(/img/businessHorizons.jpg);
    background-size: cover;
    background-attachment: fixed;
    /* border-radius: 15px; */
  }
  
  .slick-list{
    width: 100%;
    height: 400px;
    font-family: var(--font-family-2);
  }
  
  .services-h2{
    font-family: var(--font-family-1);
    font-size: 2rem !important;
  }
  .slide1-h3, .slide2-h3, .slide3-h3{
    display: flex;
    width: 100%;
    height: 400px;
    position: relative;
    z-index: 2;
    justify-content: center;
    align-items: center;
    gap: 51px;
    /* background-color: rgba(0, 140, 255, 0.205); */
  }
  .slide2{  
    background-size: cover;
    background-attachment: fixed;
    position: relative;
  }
  .slide3{  
    background-image: url(/img/coding.jpg);
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    z-index: 4;
  }
  .slidepicture{
    /* background-image: url(/img/copier.png); */
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
    height: 400px;
    position: relative;
    z-index: 0;
    margin-top: -400px;
    animation: slidepicture 45s ease-in-out infinite;
  }
  @keyframes slidepicture {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.25);
    }
    100% {
      transform: scale(1.50);
    }
  }
  
  