   a:hover{
    font-size: 0.8rem;
    font-weight: normal;
    text-decoration: none;
  }

  .orders td a{
    font-size: 0.9rem;
    text-decoration: none;
    color: #000000;
  }

  .orders>tr:hover{
    background-color: #e4dfdf;
  }