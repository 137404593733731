.centerNewCustomer{
  margin-top: 60px;
  margin-left: 140px;
 
}

.row .col-4 {
 margin-bottom: 10px;

}

.col-A{
  text-align: left;
}

.col-A input{
   margin: auto;
}

.form-control{
  width: 85%;
  background-color: #c1c1c13b;
}

.form-update{
  margin-top: 60px;
  margin-left: 60px;
}
.form .select{
  width: 85%;
  background-color: #c1c1c13b;
}
.searchCustomer{
  border-radius: 5px;
  border: 1px solid #c0bcc8;
  margin-bottom: 30px;
}
.searchCustomer:focus{
  border-style: none;
  border: 1px solid #c0bcc8 !important;
}


select#uf.form-control.form-select.form-select-sm{
  width: 85%;
  height: 30px;
  background-color: #c1c1c13b;
  border-radius: 5px;
  border: 1px solid #c0bcc8;
  margin: 0px 0px 0px 30px;
}