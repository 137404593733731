body{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
  .statusBoxList{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.8rem;
    width: 100px auto;
    gap: 10px;
  }
  
  a{
    font-size: 0.8rem;
    text-decoration: none;
  }
  a:hover{
    font-size: 0.8rem;
    font-weight: normal;
    text-decoration: none;
  }
  .orders>tr:hover{
    background-color: #e4dfdf;
  }
.statusHeader {
  display: flex;
  justify-content: flex-end;
  width: 80%;
  padding: 10px;
  border: 2px solid #6591cadb;
  border-radius: 25px;
}