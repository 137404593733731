@import url(https://fonts.googleapis.com/css2?family=Righteous&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Geologica&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cinzel&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

:root{
    --background-image: url('/img/cableinfra.jpg');
    --background-navfooter: #6b7795;
    --font-family-1:'Righteous', cursive;
    --font-family-2: 'Geologica';
    --font-family-3: 'Cinzel';
    --font-color: #282727;
}



/* Estilos globais */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

#landingBody{
    margin: 0 !important;
    padding: 0 !important;
    box-sizing: border-box !important;
    background-color: #fff;
    background-image: url('/img/cableinfra.jpg');
    background-image: var(--background-image);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-blend-mode: luminosity;
    font-family: Arial, sans-serif;
    color: #282727;
    color: var(--font-color);
    /* margin-top: 90px; */
    overflow-x: hidden;
}
#landingBody .container {
    max-width: 1200px;
    /* margin: 0 auto; */
    /* margin-top: 90px; */
    /* padding: 20px; */ 
  }

  .contactus{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  #site-contact form{
      display: flex;
      width: 800px;
      height: 400px;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 200px;
      /* border: 1px solid white; */

  }

  #site-contact form label{
    font-size: 1.5rem;
    font-family: 'Righteous', cursive;
    font-family: var(--font-family-1);
  }

  #site-contact form input[type="text"], input[type="email"]{
    border-radius: 20px;
    height: 35px;
    width: 500px;
    font-size: 1.3em;
    padding-left: 15px;
    }

    #site-contact form .line{
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 10px;
    }

    #site-contact .btnSubmit{
      width: 180px;
      height: 35px;
      border-radius: 20px;
      background-color: #fff;
      border-style: inset;
      border: 2px solid rgb(196, 200, 204);
    }

    .rowContact{
      display: flex;
      background-color: #337ca738;
      justify-content: center;
      justify-items: center;
      align-content: center;
      align-items: center;
      padding: 20px;
      font-family: 'Righteous', cursive;
      font-family: var(--font-family-1);
    }
    #site-contact .rowContact .col-4{
      text-align: center;
      font-size: 1.5rem;
    }
  
  .general{
    margin-top: 90px;
    background-color: red;
  }
  
  /* Cabeçalho */
 
  #landingBody > nav {
  flex-direction: row;
  background-color: #6b7795;
  background-color: var(--background-navfooter);
  position: fixed !important;
  width: 100% !important;
  height: 100px !important;
  margin-top: -80px !important;
  justify-content: space-around;
  align-items: baseline;
  overflow: hidden;
  z-index: 99;
  font-family: 'Righteous', cursive;
  font-family: var(--font-family-1)
}


#landingBody>nav .logo {
  color: #fff;
  font-size: 24px;
  text-decoration: none;
}

#landingBody>nav ul {
  list-style: none;
  display: flex;
}

#landingBody>nav ul li {
  margin-right: 20px;
}

#landingBody>nav ul li:first-child {
  margin-left: auto;
}

#landingBody>nav ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
}

#landingBody>nav ul li a:hover {
  color: #062838;
}

 

  /* Seção Serviços */
  #services {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100vh;
    padding: 20px;
    /* background-image: url('/img/techservice.jpg'); */
      /* background-color: #c7c6c6; */
    background-attachment: fixed;
    color: floralwhite;
  }
  
  #services h2 {
    font-size: 3rem;
    margin-top: 90px;
    margin-bottom: 10px;
  }
  
  #services p {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  /* Seção Contato */
  #site-contact {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-content: center;
    height: auto;
    background-image: url(/img/contactus.jpg);
    background-attachment: fixed;
    color: #fff;
  }
  
  #site-contact h2 {
    font-size: 30px;
    margin-top: 90px;
    /* margin-bottom: 10px; */
  }
  
  #site-contact p {
    font-size: 2.4rem;
    text-shadow: -1px -1px 0 #2e2f66, 4px -1px 0 #2c3466, -1px 3px 0 #565b5f, 1px 1px 0 #53115c; 
    margin-bottom: 50px;
  }
  
  .image-container {
    position: relative;
    display: inline-block;
  }
  .imgHr{   
    width: 600px;
    height: 500px;
    object-fit: cover;
    border-radius: 5px;
  }


  /* sections */

  section{
    display: flex;
    width: 100vw;
    min-height: 100vh;
    /* background-color: yellowgreen; */
  }
  .oversection{
    width: 100%;
    min-height: 1000px;
    height: 100%;
    /* height: auto !important; */
    overflow: hidden;
    background-image: url(/img/partners.jpg);
    background-attachment: fixed;
    /* position: relative; */
  }

  .rowA{
    margin-top: 250px;
  }
  .rowA, .rowB, .rowC, .rowD{
    display: flex;
    flex-direction: row;
    min-height: 400px;
    height: auto;
    /* border-bottom: 1px solid white;   */
    align-items: center;
    font-size: 2.5rem;
    font-weight: bold;
    font-family: 'Righteous', cursive;
    font-family: var(--font-family-1);
    position: relative;
    z-index: 5;
  }
  .rowA, .rowC{
    justify-content: flex-end !important;
  }
  .rowB, .rowD{
    color:#ffffff;
    justify-content: flex-start !important;
  }

  img.rowCircle{
    width: 230px;
    height: 230px;
    border-radius: 50%;
    z-index: 3;
    -webkit-animation: scaleAnimation 2s ease-in-out infinite;
            animation: scaleAnimation 2s ease-in-out infinite;
  }

  @-webkit-keyframes scaleAnimation {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    25% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }
    75% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes scaleAnimation {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    25% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }
    75% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  .rowCircleL{
    display: flex;
    justify-content: flex-start !important;
    margin-top: -700px;
    margin-left: 140px;
    z-index: 5;
    -webkit-animation: scaleAnimation 22s ease-in-out infinite;
            animation: scaleAnimation 22s ease-in-out infinite;
  }
  .rowCircleR{
    display: flex;
    justify-content: flex-end !important;
    margin-top: -750px;
    margin-right: 140px;
    z-index: 5;
  }
  .overparagraph{
    z-index: 10;
  }
  .general .col-6:nth-child(1){
    width: 50%;
    height: 50%;
    padding: 20px;
    background-color: #ffffff95;
  }
  .general .col-6:nth-child(2){
    width: 50%;
    height: 50%;
    padding: 20px;
    background-color: #f5868695;
  }

  .material-symbols-outlined {
    font-variation-settings:
    'FILL' 0,
    'wght' 800,
    'GRAD' 0,
    'opsz' 48
  }

  .main-title{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 70px;
    justify-content: space-around;
    /* margin-bottom: 20px; */
    margin-top: 90px;
    margin-left: -100px;
  }
  .box1, .box2, .box3{
    display: flex;
    grid-gap: 15px;
    gap: 15px;
    /* padding: 10px; */
    /* border-bottom: 1px solid #313030 !important; */
    align-content: center;
  }
  .boxTitle{
    font-size: 1.4rem;
    font-family: 'Righteous', cursive;
    font-family: var(--font-family-1);
  }
  .paragraph{
    font-family: 'Cinzel';
    font-family: var(--font-family-3);
    font-size: 1.5em;
  }
  .gradient-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(6, 85, 158));
    margin-top: 90px;
  }
  .landing{
    width: 100%;
    height: 100%;
  }
 
  .reclam{
    font-size: 6rem;
    color: #777337; 
  }

  #landingBody > nav.hide-navbar {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    transition: -webkit-transform 1.8s ease;
    transition: transform 1.8s ease;
    transition: transform 1.8s ease, -webkit-transform 1.8s ease;
  }

    /* Rodapé */
    footer {
      display:flex;
      background-color: #6b7795;
      background-color: var(--background-navfooter);
      height: 100px;
      color: #fff;
      text-align: center;
      justify-content: center;
      align-items: center;
    }
    
    footer p {
      font-size: 14px;
    }


  /* @media queryes */

  
@media (max-width: 768px) {
  #landingBody>nav {
  flex-direction: column;
}

#landingBody>nav ul {
  margin-top: 10px;
  justify-content: center;
}

#landingBody>nav ul li {
  margin: 0;
  margin-bottom: 10px;
}

#landingBody>nav ul li:first-child {
  margin-top: auto;
}
}


body{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    background-color: rgba(19, 21, 117, 0.86);
}

a{
    color: #34117b;
    text-decoration: none;
}
a:hover{
    color: #34117b;
    font-weight: bold;
}
.centerLogin{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90vh;    
}

h4{
    color: #c0bcc8;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.loginBox{
    margin: 0px;
    width: 330px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #c0bcc8;
    border-radius: 10px;
    border: 3px solid #3f3d3d54;
}

.loginBox .form-label{
    width: 100%;
    text-align: left;
}
.loginBox input{
    width: 300px;
}
.loginBtn {
    display: flex;
    justify-content:center ;
}

.btn{
    width: 80px;
    height: 40px;
    background-color:  rgba(19, 21, 117, 0.519);
    color: #34117b;
    border: 3px solid #b6b4b454;
}
.btn:hover{
    background-color:  rgba(19, 21, 117, 0.519);
    border: 3px solid #b6b4b454;
    color: #b6b4b454;
    font-weight: bold;
}
div.message{
    font-weight: lighter;
    color: rgba(166, 41, 16, 0.979);

}
.mainBkg{
    background-image:url(/static/media/pic7.8ae32788.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position:inherit;
}
.main{
    width: 100vw;
    height: 100vh;
    background-color: rgba(131, 123, 123, 0.822);
}
.topo {
  display: flex;
  width: 100%;
  height: 80px;
  justify-content: space-around;
  align-items: center;
  background-color: #6591cadb;
  color: #34117b;
  margin-top: 0px !important;
  margin-left: 0px !important;
  position: fixed;
}
.btnLogout {
  border: none !important;
  background-color: transparent;
}

.btnLogout:hover {
  font-weight: bold !important;
}

img.logo {
  width: 180px;
  height: 50px;
}

nav{
  overflow-x: hidden;
  width: 200px !important;
  height: 100vh !important;
  text-align: left;
  font-size: 0.8rem;
}

a{
  font-size: 0.9rem;
}

a#nav-dropdown.dropdown-toggle.nav-link{
  margin: 0px;
  width: 100%;
  padding: 2px;
  color: #353535;
  font-size: 0.9rem;
}

.alonelink{
  font-size:0.9rem;
  margin-left: 1px;  
}

.alonelink:hover{
  font-size:0.9rem;
 color: #353535;
}

span{
  font-size:0.9rem;
}

a#nav-dropdown.dropdown-toggle.nav-link{
  font-size: 0.9rem;
  margin-bottom: 10px ;
}

a#nav-dropdown.dropdown-toggle.show.nav-link{
  font-size: 0.9rem;
  padding: 1px;
}

a#nav-dropdown.dropdown-toggle.nav-link::after{
  font-size: 0.9rem;
visibility: hidden;
}
a.dropdown-item.active{
  font-size: 0.9rem;
  color:#353535;
  background-color: #a2a2a28d;
}


body {
  margin: 0;
}
.center {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  padding-top: 110px;
  /* background-color: yellowgreen; */
}

.homeBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 24%;
  min-width: 350px;
  padding: 20px !important;
  height: 150px auto;
  padding: 5px;
  border-radius: 5px;
  border: 3px solid #6591cadb;
  font-size: 0.7rem;
  margin-bottom: 20px;
/* background-color: #c1c1c13b; */
}
.boxText {
  text-align: left;
  font-size: 0.8rem;
  line-height: 1.8rem;
}
.boxText a {
  color: rgb(26, 25, 25);
}
span {
  font-size: 0.8rem;
  font-weight: bold;
}
.mainTitle {
  display: flex;
  align-self: center;
  margin-top: 0px;
  margin-bottom: 10px;
}

.section1 {
  display: flex;
  align-items: center;
}

.statusBox{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.8rem;
  min-width: 280px;
  grid-gap: 10px;
  gap: 10px;
}
.statusOpen {
  width: 10px;
  height: 10px;
  border-radius: 90px;
  border: 0.5px solid #000;
  background-color: rgb(242, 242, 20);
}
.statusPending {
  width: 10px;
  height: 10px;
  border-radius: 90px;
  border: 0.5px solid #000;
  background-color: rgb(226, 10, 10);
}
.statusClosed {
  width: 10px;
  height: 10px;
  border-radius: 90px;
  border: 0.5px solid #000;
  background-color: rgb(8, 226, 30);
}
.statusText{
  width: 60px;
}
.statusBox span{
  display: flex;
  width: 50px;
  justify-content: center;

}

/* .............................................. */
.container {
  max-width: 100%;
}

table {
  width: 100% !important;
  border-collapse: collapse;
  font-size: 0.8rem;
}

table th,
table td {
  padding: 8px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

table thead {
  background-color: #f5f1f1a6;
}

table tbody tr:nth-child(even) {
  background-color: #f9f9f93f;
}

table tbody tr:hover {
  background-color: #e4dfdf;
}

table td a {
  color: rgb(10, 10, 10);
  font-size: 0.8rem !important;
}

body {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    background-color: #c9f8f93f;
}
.center{
    margin-left: 190px !important;
    width: 80% !important;   
}


.logo {
    width: 105px;
    height: 55px;
    border-radius: 2px;
}

nav {
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 71px;
    bottom: 0;
    width: 120px;
    padding: 20px;
    bottom: 0;
    left: 0;
    z-index: 1;
    overflow-y: auto;
  }
  

nav a {
    color: #000;
    text-decoration: none;
    margin-bottom: 10px;
    font-size: 16px;
}

.btnLogout {
    border: none !important;
    background-color: transparent;
}

.btnLogout:hover {
    font-weight: bold !important;
}

h1 {
    font-size: 22px;
}

.main-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    /* margin: -10px; para compensar o espaçamento padrão das colunas */
    justify-content: space-around;
}

.col-4 {
    flex: 0 0 30%;
    max-width: 30%;
    padding: 10px;
    min-width: 240px;
    margin-bottom: 10px;
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 10px;
    min-width: 240px;
    margin-bottom: 10px;
}

.col-A {
    border: 1px solid gray;
    border-radius: 5px;
    padding: 10px;
    margin-right: 5px;
    min-width: 240px;
    margin-bottom: 10px;
}

/* Para a última coluna da primeira linha, remova a margem à direita */
.row .col-4:last-child {
    margin-right: 0;
}

.text-center {
    text-align: center;
}

.form-group {
    background-color: rgba(0, 0, 255, 0.256);
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid rgb(4, 4, 95);

}

.form-label {
    font-size: 11px;
    margin-top: 12px;
}

.form-control {
    width: 210px;
    height: 10px;
    font-size: 13px;
    background-color: rgba(245, 238, 247, 0.901);
}

.form-btn {
    margin-top: 15px;
}

.readOnly {
    font-weight: bold;
}

.message {
    font-weight: bold;
    color: red;
}

   a:hover{
    font-size: 0.8rem;
    font-weight: normal;
    text-decoration: none;
  }

  .orders td a{
    font-size: 0.9rem;
    text-decoration: none;
    color: #000000;
  }

  .orders>tr:hover{
    background-color: #e4dfdf;
  }
.centerNewCustomer{
  margin-top: 60px;
  margin-left: 140px;
 
}

.row .col-4 {
 margin-bottom: 10px;

}

.col-A{
  text-align: left;
}

.col-A input{
   margin: auto;
}

.form-control{
  width: 85%;
  background-color: #c1c1c13b;
}

.form-update{
  margin-top: 60px;
  margin-left: 60px;
}
.form .select{
  width: 85%;
  background-color: #c1c1c13b;
}
.searchCustomer{
  border-radius: 5px;
  border: 1px solid #c0bcc8;
  margin-bottom: 30px;
}
.searchCustomer:focus{
  border-style: none;
  border: 1px solid #c0bcc8 !important;
}


select#uf.form-control.form-select.form-select-sm{
  width: 85%;
  height: 30px;
  background-color: #c1c1c13b;
  border-radius: 5px;
  border: 1px solid #c0bcc8;
  margin: 0px 0px 0px 30px;
}
 /* Seção Sobre */
 #about {
    display: flex;
    width: 100%;
    padding: 20px;
    /* margin: 0px 0px 0px 0px !important;  */
    margin: auto; 
    /* margin-top: 90px; */
    flex-direction: column;
    align-items: center;
    /* text-align: center; */
    text-align: justify;
    /* background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(203, 213, 223)); */
    font-size: 14px;
    font-weight: 700;
  }
  
  #about h1 {
    font-size: 28px;
    margin-bottom: 10px;
    font-family: var(--font-family-3);
    font-weight: italic;
  }
  
  #about p {
    font-size: 16px;
    margin-bottom: 20px;
    font-family: var(--font-family-2);
  }
  
  #about button {
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .about-box{
    margin-left: -20px !important;
    display: flex;
    width: 70%;
    min-height: 400px;
    height: auto;
    justify-content: space-around;
    /* background-color: rgba(255, 255, 0, 0.336); */
  }
  .aboutTitle{
    margin-top: 90px;
    font-family: var(--font-family-1);
    font-size: 3rem;
    color: #3a5b8b;
  }
  #about .col-4{
    display: flex;
    flex-direction: column;
    margin: 0px;
    align-content: center;
    padding: 25px;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.597);
  }
  .curved-line2 {
    margin-left: 0px;
    width: 100vw;
    height: 34px;
    background: linear-gradient(to left, transparent 10%, rgb(12, 87, 117) 50%, transparent 90%);
    background-size: 200% 100%;
    -webkit-transform: scaleX(1) rotate(0deg);
            transform: scaleX(1) rotate(0deg);
    -webkit-animation: moveLinearGradient 7s linear infinite;
            animation: moveLinearGradient 7s linear infinite;
    position: relative;
    z-index: 7;
  }
  @-webkit-keyframes moveLinearGradient {
    0% {
      background-position: 100% 50%;
    }
    50% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }
  @keyframes moveLinearGradient {
    0% {
      background-position: 100% 50%;
    }
    50% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }
  .col-4 ul li{
    margin-top: 25px;
  }

  .boxImg{
    width: 100%;
    height: 100%;
    opacity: 0.6;
  }
.videoBan1{
    margin-top: 90px;
    width: 100vw;
    height: 100vh;
    background-image: url('/img/teamworking.jpg');
    background-attachment: fixed;
  }
  
  .videoBan2{
    margin-top: 90px;
    width: 100vw;
    height: 100vh;
    /* background-attachment: fixed; */
  }
  .videoService{
    position: relative;
    width: 100%;
    margin-top: -400px;
    z-index: 1;
    border-radius: 15px !important;
  }
  .slick-slider{
    display: flex;
    width: 100vw;
    height: 400px;
    background-color: rgba(153, 205, 50, 0.226);
    /* border-radius: 15px; */
    /* overflow: hidden; */
  }
  
  .slick-initialized{
    background-color: #06283854;
    background-image: url(/img/businessHorizons.jpg);
    background-size: cover;
    background-attachment: fixed;
    /* border-radius: 15px; */
  }
  
  .slick-list{
    width: 100%;
    height: 400px;
    font-family: var(--font-family-2);
  }
  
  .services-h2{
    font-family: var(--font-family-1);
    font-size: 2rem !important;
  }
  .slide1-h3, .slide2-h3, .slide3-h3{
    display: flex;
    width: 100%;
    height: 400px;
    position: relative;
    z-index: 2;
    justify-content: center;
    align-items: center;
    grid-gap: 51px;
    gap: 51px;
    /* background-color: rgba(0, 140, 255, 0.205); */
  }
  .slide2{  
    background-size: cover;
    background-attachment: fixed;
    position: relative;
  }
  .slide3{  
    background-image: url(/img/coding.jpg);
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    z-index: 4;
  }
  .slidepicture{
    /* background-image: url(/img/copier.png); */
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
    height: 400px;
    position: relative;
    z-index: 0;
    margin-top: -400px;
    -webkit-animation: slidepicture 45s ease-in-out infinite;
            animation: slidepicture 45s ease-in-out infinite;
  }
  @-webkit-keyframes slidepicture {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      -webkit-transform: scale(1.25);
              transform: scale(1.25);
    }
    100% {
      -webkit-transform: scale(1.50);
              transform: scale(1.50);
    }
  }
  @keyframes slidepicture {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      -webkit-transform: scale(1.25);
              transform: scale(1.25);
    }
    100% {
      -webkit-transform: scale(1.50);
              transform: scale(1.50);
    }
  }
  
  
*{
    margin: 0px; 
    padding: 0px;
    box-sizing: border-box;
}

  #mainBanner{
    width: 100vw;
    height: 100vh;
  }
  .sliderMainBanner .slick-slider{
    width: 100vw;
    height: 100vh;
  }
  
  .sliderMainBanner .slick-initialized{
    width: 100vw;
    height: 100vh;
    /* background-image: url(/img/businessHorizons.jpg); */
    background-size: cover;
    background-attachment: fixed;
  }
  
  .sliderMainBanner .slick-list{
    width: 100vw;
    height: 100vh;
    font-family: var(--font-family-2);
  }
  
  .sliderMainBanner .slideBan1{
    width: 100vw;
    height: 100vh;
    background-image: url(/img/postitboard.jpg);
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    z-index: 4;
  }
  #mainBanner .sliderMainBanner .slideBan2{  
    width: 100vw;
    height: 100vh;
    background-image: url(/img/copier.png);
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    z-index: 4;
  }
  #mainBanner .sliderMainBanner .slideBan3{  
    width: 100vw;
    height: 100vh;
    background-image: url(/img/teamboat.jpg);
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    z-index: 4;
  }
  .slideBan1 h3, .slideBan2 h3, .slideBan3 h3{
    display: flex;
    width: 100vw;
    height: 100vh;
    position: relative;
    z-index: 2;
    justify-content: center;
    align-items: center;
    margin-top: -150px;
    /* gap: 51px; */
    /* background-color: rgba(0, 140, 255, 0.205); */
    color: rgb(40, 51, 40);
    text-shadow: -1px -1px 0 #b7b7b9, 4px -1px 0 #51c5c7, -1px 3px 0 #565b5f, 1px 1px 0 #b52cc7; 
    font-size: 4rem;
    font-family: var(--font-family-1);
  }
  .slideBan3 h3{
    color: rgb(245, 247, 245);
  }
  
  .btnBan{
    position: relative;
    margin-top: -250px;
  }

  #mainBanner .sliderMainBanner .slidepicture{
    width: 100%;
    height: 100vh;
    position: relative;
    background-image: url(/img/copier.png);
    background-size: cover;
    background-attachment: fixed;
    z-index: 0;
    /* margin-top: -400px; */
    /* animation: slidepicture 45s ease-in-out infinite; */
  }
  @-webkit-keyframes{
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      -webkit-transform: scale(1.25);
              transform: scale(1.25);
    }
    100% {
      -webkit-transform: scale(1.50);
              transform: scale(1.50);
    }
  }
  @keyframes{
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      -webkit-transform: scale(1.25);
              transform: scale(1.25);
    }
    100% {
      -webkit-transform: scale(1.50);
              transform: scale(1.50);
    }
  }
  #mainBanner .sliderMainBanner .videoBan1{
    /* margin-top: 90px; */
    width: 100vw;
    height: 100vh;
    /* background-image: url('/img/teamworking.jpg'); */
    /* background-attachment: fixed; */
  }
  
  #mainBanner .sliderMainBanner .videoBan2{
    /* margin-top: 90px; */
    width: 100vw;
    height: 100vh;
    /* background-attachment: fixed; */
  }

  
  #mainBanner .sliderMainBanner .videoService{
    position: relative;
    width: 100vw;
    height: 100vh;
    /* margin-top: -400px; */
    z-index: 1;
    /* border-radius: 15px !important; */
  }
.btnMore{
    width: 220px;
    height: 100px;
    border-radius: 25px;
}
.btnMinus{
    width: 110px;
    height: 50px;
    border-radius: 25px;
    background-color: rgba(8, 17, 136, 0.342);
    font-size: 0.8rem;
    padding: 4px;
    border: 2px solid #10d9e7;
    color: #fff;
}

  /* Seção O que fazemos */
  #whatwedo {
    display: flex;
    width: 100%;
    padding: 20px;
    /* margin: 0px 0px 0px 0px !important;  */
    margin: auto; 
    /* margin-top: 90px; */
    flex-direction: column;
    align-items: center;
    /* text-align: center; */
    text-align: justify;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(203, 213, 223));
    font-size: 14px;
    font-weight: 700;
  }
     
  #whatwedo .row{
    /* margin-bottom: 70px; */
    /* background-color: rgb(86, 104, 138); */
  }
  
  #whatwedo h1 {
    font-size: 28px;
    margin-bottom: 10px;
    font-family: var(--font-family-3);
    font-weight: italic;
  }
  
  #whatwedo p {
    font-size: 16px;
    margin-bottom: 20px;
    font-family: var(--font-family-2);
  }
  
  #whatwedo button {
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .whatwedo-box{
    margin-left: -20px !important;
    display: flex;
    width: 70%;
    min-height: 400px;
    height: auto;
    justify-content: space-around;
    border-radius: 5px;
    padding: 20px;
    margin-top: 15px;
    background-color: rgba(5, 40, 75, 0.827);
    color:aliceblue;
  }
  .whatwedoTitle{
    margin-top: 90px;
    font-family: var(--font-family-1);
    font-size: 3rem;
    color: #3a5b8b;
  }
  #whatwedo .whatwedo-box .box1, .box2, .box3{
    margin-bottom: 35px;
  }

  #whatwedo  .boxTitle{
    text-shadow: -1px -1px 0 #2e2f66, 4px -1px 0 #2c3466, -1px 3px 0 #c9ced2, 1px 1px 0 #53115c; 
    letter-spacing: 3px;
  }
  #whatwedo .col-4{
    display: flex;
    flex-direction: column;
    margin: 0px;
    align-content: center;
    padding: 25px;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.597);
  }

  .curved-line2 {
    margin-left: 0px;
    width: 100vw;
    height: 34px;
    /* background: linear-gradient(to left, transparent 10%, rgb(12, 87, 117) 50%, transparent 90%); */
    background: linear-gradient(to left, transparent 10%, rgb(0, 255, 247) 50%, transparent 90%);
    background-size: 200% 100%;
    -webkit-transform: scaleX(1) rotate(0deg);
            transform: scaleX(1) rotate(0deg);
    -webkit-animation: moveLinearGradient 7s linear infinite;
            animation: moveLinearGradient 7s linear infinite;
    position: relative;
    z-index: 7;
  }
  @-webkit-keyframes moveLinearGradient {
    0% {
      background-position: 100% 50%;
    }
    50% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }
  @keyframes moveLinearGradient {
    0% {
      background-position: 100% 50%;
    }
    50% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }
   li{
    margin-top: 25px;
    font-size: 1.2rem;
    color: rgb(232, 232, 232);
  }
  #whatwedo .material-symbols-outlined{
    color:rgb(0, 255, 247);
    margin-right: 15px;
  }
body{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
  .statusBoxList{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.8rem;
    width: 100px auto;
    grid-gap: 10px;
    gap: 10px;
  }
  
  a{
    font-size: 0.8rem;
    text-decoration: none;
  }
  a:hover{
    font-size: 0.8rem;
    font-weight: normal;
    text-decoration: none;
  }
  .orders>tr:hover{
    background-color: #e4dfdf;
  }
.statusHeader {
  display: flex;
  justify-content: flex-end;
  width: 80%;
  padding: 10px;
  border: 2px solid #6591cadb;
  border-radius: 25px;
}
.section1{
    margin-top: 60px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; 
    justify-content: space-between;
    width: 80%;
    flex-direction: column;
    margin-left: 160px;
  
   }

   .centerBox{
    display: flex;
    flex-direction: column;
    min-height: 50vh;
    height: auto;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 5px;
   }
   
   .title{
       margin-top: 20px;
   }
  
   .searchCustomer{
       border-radius: 5px;
       margin-bottom: 10px;
       background-color: #c1c1c13b;
       font-size: 0.8rem;
   }

   .searchCustomer:focus{
       border-style: none;
       border-style: none!important;
       font-size: 0.9rem;
   }
   select, option{
    border-radius: 5px;
    margin-bottom: 10px;
    background-color: #d0cecef5;
}

   .form-btn{
       width: 80px;
       height:40px;
       border-radius: 5px;
       background-color: rgba(19, 21, 117, 0.519);
       color: #34117b;
       border: 3px solid #b6b4b454;
   }


    .osDate{
        width: 100px;
        font-size: 0.9rem;
        margin: 0px 10px 0px 0px;
        align-self: flex-start;
    }

    label.row.form-label{
        color: red;
        align-self: flex-start;
    }
    label.form-label{
        align-self: flex-start;
    }
    .col-4{
        text-align: left;
    }
    .returnContent{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
       
    }
    .labelTitle{
        color: rgb(100, 97, 97);
        font-size: 0.75rem;
    }
    .labelContent{
        color: rgb(26, 26, 25);
        font-size: 0.75rem;
        margin-left: 10px;
    }

    .row.rowLabel{
        text-align: left;
        justify-content: flex-start;
    }
    .searchCustomer.form-control.form-control-lg{
        margin-left: 10px;
        padding-left: 10px;
        width: 50rem;
    }
    table{
        width: 50rem !important;
    }
    td .labelTitle{
        font-size: 0.75rem;
    }
    td .labelContent{
        font-size: 0.9rem;
    }
    tr td:nth-child(1){
        width: 80px;
    }
    tr td:nth-child(2){
        width: 30px;
    }
    tr td:nth-child(3){
        width: 110px;
        margin-right: 30px;
    }
    tr td:nth-child(4){
        width: 80px;
 
    }    
    tr td:nth-child(5){
        width: 40px;
        margin-left: -30px;
    
    }
    tr td:nth-child(6){
        width: 80px;
    }
    
.sectionCustomerView{
    width: 80%;
    height: 70vh;
    border: 3px solid #c0bcc8;
    border-radius: 5px;
    background-color: #f5f1f1a6;
    line-height: 20px;
}
.customerView{    
    padding: 15px;
    font-size: 1rem;
    text-align: left;
    font-size: 0.9rem;
}
label{
    font-weight: bold;
}
 
  a{
    font-size: 0.8rem;
    text-decoration: none;
  }
  a:hover{
    font-size: 0.8rem;
    font-weight: normal;
    text-decoration: none;
  }
  .orders>tr:hover{
    background-color: #e4dfdf;
  }

  .row-Title{
    display: flex;
    flex-direction: row;
    grid-gap: 300px;
    gap: 300px;
  }
  .row-Data{
    display: flex;
    flex-wrap: wrap;
    grid-gap: 125px;
    gap: 125px;
  }

body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #fcf6f6;
  }
  
  /* Estilo da barra de navegação superior */
  .navbar-top {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background-color: #3b6769;
    color: #fff;
    padding: 10px;
  }
  
  /* Estilo da barra de navegação lateral */
  .navbar-side {
    position: fixed;
    top: 40px;
    left: 0;
    width: 5%;
    height: calc(100% - 180px);
    background-color: #eeeeee42;
    padding: 10px;
    z-index: 2;
  }
  
  /* Estilo dos itens da barra de navegação lateral */
  .navbar-side ul {
    list-style: none;
    padding: 0;
  }
  
  .navbar-side li {
    margin-bottom: 10px;
  }
  
  .navbar-side a {
    text-decoration: none;
    color: #333;
  }
  a{
    text-decoration: none;
    color: #333;
  }
  /* Estilo da página central */
  .content {
    margin-top: 80px;
    margin-left: 200px;
    width: 100%;
    min-height: 75vh;
    height: 1200px;
    padding: 20px;
  }
  
  /* Estilo do rodapé */
  .footer {
    display: flex;
    justify-content: space-around;
    background-color: #3b6769;
    color: #fff;
    padding: 10px;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px auto;
    z-index: 3;
  }
  
  /* Estilos responsivos */
  @media (max-width: 768px) {
    /* Reduz a largura da barra de navegação lateral para 100% em telas menores */
    .navbar-side {
      width: 100%;
      position: static;
      margin-bottom: 20px;
    }
    
    /* Move a margem da página central para o topo */
    .content {
      margin-left: 0;
    }
}
ul{
    list-style: none;
}
li{
    padding: 5px;
}

body{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    /* background-color: rgb(167, 167, 180); */
}

a{
    color: #34117b;
    text-decoration: none;
}
a:hover{
    color: #34117b;
}
.centerLogin{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90vh;    
}

h4{
    color: #c0bcc8;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.formForget{
    width: 350px;
}
.loginBox{
    margin: 0px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #c0bcc8;
    border-radius: 10px;
    border: 3px solid #3f3d3d54;
}

.loginBox .form-label{
    width: 100%;
    text-align: left;
}

.loginBtn {
    display: flex;
    justify-content:center ;
}

.btn{
    width: 80px;
    height: 40px;
    background-color:  rgba(19, 21, 117, 0.519);
    color: #34117b;
    border: 3px solid #b6b4b454;
}
.btn:hover{
    background-color:  rgba(19, 21, 117, 0.519);
    border: 3px solid #b6b4b454;
    color: #b6b4b454;
    font-weight: bold;
}
div.message{
    font-weight: lighter;
    color: rgba(166, 41, 16, 0.979);
}
.mainBkg{
    background-size: cover;
    background-repeat: no-repeat;
    background-position:inherit;
}
.main{
    width: 100vw;
    height: 100vh;
    background-color: rgba(123, 127, 131, 0.822);
}
