  /* Seção O que fazemos */
  #whatwedo {
    display: flex;
    width: 100%;
    padding: 20px;
    /* margin: 0px 0px 0px 0px !important;  */
    margin: auto; 
    /* margin-top: 90px; */
    flex-direction: column;
    align-items: center;
    /* text-align: center; */
    text-align: justify;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(203, 213, 223));
    font-size: 14px;
    font-weight: 700;
  }
     
  #whatwedo .row{
    /* margin-bottom: 70px; */
    /* background-color: rgb(86, 104, 138); */
  }
  
  #whatwedo h1 {
    font-size: 28px;
    margin-bottom: 10px;
    font-family: var(--font-family-3);
    font-weight: italic;
  }
  
  #whatwedo p {
    font-size: 16px;
    margin-bottom: 20px;
    font-family: var(--font-family-2);
  }
  
  #whatwedo button {
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .whatwedo-box{
    margin-left: -20px !important;
    display: flex;
    width: 70%;
    min-height: 400px;
    height: auto;
    justify-content: space-around;
    border-radius: 5px;
    padding: 20px;
    margin-top: 15px;
    background-color: rgba(5, 40, 75, 0.827);
    color:aliceblue;
  }
  .whatwedoTitle{
    margin-top: 90px;
    font-family: var(--font-family-1);
    font-size: 3rem;
    color: #3a5b8b;
  }
  #whatwedo .whatwedo-box .box1, .box2, .box3{
    margin-bottom: 35px;
  }

  #whatwedo  .boxTitle{
    text-shadow: -1px -1px 0 #2e2f66, 4px -1px 0 #2c3466, -1px 3px 0 #c9ced2, 1px 1px 0 #53115c; 
    letter-spacing: 3px;
  }
  #whatwedo .col-4{
    display: flex;
    flex-direction: column;
    margin: 0px;
    align-content: center;
    padding: 25px;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.597);
  }

  .curved-line2 {
    margin-left: 0px;
    width: 100vw;
    height: 34px;
    /* background: linear-gradient(to left, transparent 10%, rgb(12, 87, 117) 50%, transparent 90%); */
    background: linear-gradient(to left, transparent 10%, rgb(0, 255, 247) 50%, transparent 90%);
    background-size: 200% 100%;
    transform: scaleX(1) rotate(0deg);
    animation: moveLinearGradient 7s linear infinite;
    position: relative;
    z-index: 7;
  }
  @keyframes moveLinearGradient {
    0% {
      background-position: 100% 50%;
    }
    50% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }
   li{
    margin-top: 25px;
    font-size: 1.2rem;
    color: rgb(232, 232, 232);
  }
  #whatwedo .material-symbols-outlined{
    color:rgb(0, 255, 247);
    margin-right: 15px;
  }