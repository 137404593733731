@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Geologica&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel&display=swap');

:root{
    --background-image: url('/img/cableinfra.jpg');
    --background-navfooter: #6b7795;
    --font-family-1:'Righteous', cursive;
    --font-family-2: 'Geologica';
    --font-family-3: 'Cinzel';
    --font-color: #282727;
}



/* Estilos globais */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

#landingBody{
    margin: 0 !important;
    padding: 0 !important;
    box-sizing: border-box !important;
    background-color: #fff;
    background-image: var(--background-image);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-blend-mode: luminosity;
    font-family: Arial, sans-serif;
    color: var(--font-color);
    /* margin-top: 90px; */
    overflow-x: hidden;
}
#landingBody .container {
    max-width: 1200px;
    /* margin: 0 auto; */
    /* margin-top: 90px; */
    /* padding: 20px; */ 
  }

  .contactus{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  #site-contact form{
      display: flex;
      width: 800px;
      height: 400px;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 200px;
      /* border: 1px solid white; */

  }

  #site-contact form label{
    font-size: 1.5rem;
    font-family: var(--font-family-1);
  }

  #site-contact form input[type="text"], input[type="email"]{
    border-radius: 20px;
    height: 35px;
    width: 500px;
    font-size: 1.3em;
    padding-left: 15px;
    }

    #site-contact form .line{
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 10px;
    }

    #site-contact .btnSubmit{
      width: 180px;
      height: 35px;
      border-radius: 20px;
      background-color: #fff;
      border-style: inset;
      border: 2px solid rgb(196, 200, 204);
    }

    .rowContact{
      display: flex;
      background-color: #337ca738;
      justify-content: center;
      justify-items: center;
      align-content: center;
      align-items: center;
      padding: 20px;
      font-family: var(--font-family-1);
    }
    #site-contact .rowContact .col-4{
      text-align: center;
      font-size: 1.5rem;
    }
  
  .general{
    margin-top: 90px;
    background-color: red;
  }
  
  /* Cabeçalho */
 
  #landingBody > nav {
  flex-direction: row;
  background-color: var(--background-navfooter);
  position: fixed !important;
  width: 100% !important;
  height: 100px !important;
  margin-top: -80px !important;
  justify-content: space-around;
  align-items: baseline;
  overflow: hidden;
  z-index: 99;
  font-family: var(--font-family-1)
}


#landingBody>nav .logo {
  color: #fff;
  font-size: 24px;
  text-decoration: none;
}

#landingBody>nav ul {
  list-style: none;
  display: flex;
}

#landingBody>nav ul li {
  margin-right: 20px;
}

#landingBody>nav ul li:first-child {
  margin-left: auto;
}

#landingBody>nav ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
}

#landingBody>nav ul li a:hover {
  color: #062838;
}

 

  /* Seção Serviços */
  #services {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100vh;
    padding: 20px;
    /* background-image: url('/img/techservice.jpg'); */
      /* background-color: #c7c6c6; */
    background-attachment: fixed;
    color: floralwhite;
  }
  
  #services h2 {
    font-size: 3rem;
    margin-top: 90px;
    margin-bottom: 10px;
  }
  
  #services p {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  /* Seção Contato */
  #site-contact {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-content: center;
    height: auto;
    background-image: url(/img/contactus.jpg);
    background-attachment: fixed;
    color: #fff;
  }
  
  #site-contact h2 {
    font-size: 30px;
    margin-top: 90px;
    /* margin-bottom: 10px; */
  }
  
  #site-contact p {
    font-size: 2.4rem;
    text-shadow: -1px -1px 0 #2e2f66, 4px -1px 0 #2c3466, -1px 3px 0 #565b5f, 1px 1px 0 #53115c; 
    margin-bottom: 50px;
  }
  
  .image-container {
    position: relative;
    display: inline-block;
  }
  .imgHr{   
    width: 600px;
    height: 500px;
    object-fit: cover;
    border-radius: 5px;
  }


  /* sections */

  section{
    display: flex;
    width: 100vw;
    min-height: 100vh;
    /* background-color: yellowgreen; */
  }
  .oversection{
    width: 100%;
    min-height: 1000px;
    height: 100%;
    /* height: auto !important; */
    overflow: hidden;
    background-image: url(/img/partners.jpg);
    background-attachment: fixed;
    /* position: relative; */
  }

  .rowA{
    margin-top: 250px;
  }
  .rowA, .rowB, .rowC, .rowD{
    display: flex;
    flex-direction: row;
    min-height: 400px;
    height: auto;
    /* border-bottom: 1px solid white;   */
    align-items: center;
    font-size: 2.5rem;
    font-weight: bold;
    font-family: var(--font-family-1);
    position: relative;
    z-index: 5;
  }
  .rowA, .rowC{
    justify-content: flex-end !important;
  }
  .rowB, .rowD{
    color:#ffffff;
    justify-content: flex-start !important;
  }

  img.rowCircle{
    width: 230px;
    height: 230px;
    border-radius: 50%;
    z-index: 3;
    animation: scaleAnimation 2s ease-in-out infinite;
  }

  @keyframes scaleAnimation {
    0% {
      transform: scale(1);
    }
    25% {
      transform: scale(1.1);
    }
    50% {
      transform: scale(1.2);
    }
    75% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  .rowCircleL{
    display: flex;
    justify-content: flex-start !important;
    margin-top: -700px;
    margin-left: 140px;
    z-index: 5;
    animation: scaleAnimation 22s ease-in-out infinite;
  }
  .rowCircleR{
    display: flex;
    justify-content: flex-end !important;
    margin-top: -750px;
    margin-right: 140px;
    z-index: 5;
  }
  .overparagraph{
    z-index: 10;
  }
  .general .col-6:nth-child(1){
    width: 50%;
    height: 50%;
    padding: 20px;
    background-color: #ffffff95;
  }
  .general .col-6:nth-child(2){
    width: 50%;
    height: 50%;
    padding: 20px;
    background-color: #f5868695;
  }

  .material-symbols-outlined {
    font-variation-settings:
    'FILL' 0,
    'wght' 800,
    'GRAD' 0,
    'opsz' 48
  }

  .main-title{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 70px;
    justify-content: space-around;
    /* margin-bottom: 20px; */
    margin-top: 90px;
    margin-left: -100px;
  }
  .box1, .box2, .box3{
    display: flex;
    gap: 15px;
    /* padding: 10px; */
    /* border-bottom: 1px solid #313030 !important; */
    align-content: center;
  }
  .boxTitle{
    font-size: 1.4rem;
    font-family: var(--font-family-1);
  }
  .paragraph{
    font-family: var(--font-family-3);
    font-size: 1.5em;
  }
  .gradient-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(6, 85, 158));
    margin-top: 90px;
  }
  .landing{
    width: 100%;
    height: 100%;
  }
 
  .reclam{
    font-size: 6rem;
    color: #777337; 
  }

  #landingBody > nav.hide-navbar {
    transform: translateY(-100%);
    transition: transform 1.8s ease;
  }

    /* Rodapé */
    footer {
      display:flex;
      background-color: var(--background-navfooter);
      height: 100px;
      color: #fff;
      text-align: center;
      justify-content: center;
      align-items: center;
    }
    
    footer p {
      font-size: 14px;
    }


  /* @media queryes */

  
@media (max-width: 768px) {
  #landingBody>nav {
  flex-direction: column;
}

#landingBody>nav ul {
  margin-top: 10px;
  justify-content: center;
}

#landingBody>nav ul li {
  margin: 0;
  margin-bottom: 10px;
}

#landingBody>nav ul li:first-child {
  margin-top: auto;
}
}

