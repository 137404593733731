body{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    /* background-color: rgb(167, 167, 180); */
}

a{
    color: #34117b;
    text-decoration: none;
}
a:hover{
    color: #34117b;
}
.centerLogin{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90vh;    
}

h4{
    color: #c0bcc8;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.formForget{
    width: 350px;
}
.loginBox{
    margin: 0px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #c0bcc8;
    border-radius: 10px;
    border: 3px solid #3f3d3d54;
}

.loginBox .form-label{
    width: 100%;
    text-align: left;
}

.loginBtn {
    display: flex;
    justify-content:center ;
}

.btn{
    width: 80px;
    height: 40px;
    background-color:  rgba(19, 21, 117, 0.519);
    color: #34117b;
    border: 3px solid #b6b4b454;
}
.btn:hover{
    background-color:  rgba(19, 21, 117, 0.519);
    border: 3px solid #b6b4b454;
    color: #b6b4b454;
    font-weight: bold;
}
div.message{
    font-weight: lighter;
    color: rgba(166, 41, 16, 0.979);
}
.mainBkg{
    background-size: cover;
    background-repeat: no-repeat;
    background-position:inherit;
}
.main{
    width: 100vw;
    height: 100vh;
    background-color: rgba(123, 127, 131, 0.822);
}