.section1{
    margin-top: 60px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; 
    justify-content: space-between;
    width: 80%;
    flex-direction: column;
    margin-left: 160px;
  
   }

   .centerBox{
    display: flex;
    flex-direction: column;
    min-height: 50vh;
    height: auto;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 5px;
   }
   
   .title{
       margin-top: 20px;
   }
  
   .searchCustomer{
       border-radius: 5px;
       margin-bottom: 10px;
       background-color: #c1c1c13b;
       font-size: 0.8rem;
   }

   .searchCustomer:focus{
       border-style: none;
       border-style: none!important;
       font-size: 0.9rem;
   }
   select, option{
    border-radius: 5px;
    margin-bottom: 10px;
    background-color: #d0cecef5;
}

   .form-btn{
       width: 80px;
       height:40px;
       border-radius: 5px;
       background-color: rgba(19, 21, 117, 0.519);
       color: #34117b;
       border: 3px solid #b6b4b454;
   }


    .osDate{
        width: 100px;
        font-size: 0.9rem;
        margin: 0px 10px 0px 0px;
        align-self: flex-start;
    }

    label.row.form-label{
        color: red;
        align-self: flex-start;
    }
    label.form-label{
        align-self: flex-start;
    }
    .col-4{
        text-align: left;
    }
    .returnContent{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
       
    }
    .labelTitle{
        color: rgb(100, 97, 97);
        font-size: 0.75rem;
    }
    .labelContent{
        color: rgb(26, 26, 25);
        font-size: 0.75rem;
        margin-left: 10px;
    }

    .row.rowLabel{
        text-align: left;
        justify-content: flex-start;
    }
    .searchCustomer.form-control.form-control-lg{
        margin-left: 10px;
        padding-left: 10px;
        width: 50rem;
    }
    table{
        width: 50rem !important;
    }
    td .labelTitle{
        font-size: 0.75rem;
    }
    td .labelContent{
        font-size: 0.9rem;
    }
    tr td:nth-child(1){
        width: 80px;
    }
    tr td:nth-child(2){
        width: 30px;
    }
    tr td:nth-child(3){
        width: 110px;
        margin-right: 30px;
    }
    tr td:nth-child(4){
        width: 80px;
 
    }    
    tr td:nth-child(5){
        width: 40px;
        margin-left: -30px;
    
    }
    tr td:nth-child(6){
        width: 80px;
    }
    