.topo {
  display: flex;
  width: 100%;
  height: 80px;
  justify-content: space-around;
  align-items: center;
  background-color: #6591cadb;
  color: #34117b;
  margin-top: 0px !important;
  margin-left: 0px !important;
  position: fixed;
}
.btnLogout {
  border: none !important;
  background-color: transparent;
}

.btnLogout:hover {
  font-weight: bold !important;
}

img.logo {
  width: 180px;
  height: 50px;
}
