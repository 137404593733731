.sectionCustomerView{
    width: 80%;
    height: 70vh;
    border: 3px solid #c0bcc8;
    border-radius: 5px;
    background-color: #f5f1f1a6;
    line-height: 20px;
}
.customerView{    
    padding: 15px;
    font-size: 1rem;
    text-align: left;
    font-size: 0.9rem;
}
label{
    font-weight: bold;
}
 
  a{
    font-size: 0.8rem;
    text-decoration: none;
  }
  a:hover{
    font-size: 0.8rem;
    font-weight: normal;
    text-decoration: none;
  }
  .orders>tr:hover{
    background-color: #e4dfdf;
  }

  .row-Title{
    display: flex;
    flex-direction: row;
    gap: 300px;
  }
  .row-Data{
    display: flex;
    flex-wrap: wrap;
    gap: 125px;
  }
