body {
  margin: 0;
}
.center {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  padding-top: 110px;
  /* background-color: yellowgreen; */
}

.homeBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 24%;
  min-width: 350px;
  padding: 20px !important;
  height: 150px auto;
  padding: 5px;
  border-radius: 5px;
  border: 3px solid #6591cadb;
  font-size: 0.7rem;
  margin-bottom: 20px;
/* background-color: #c1c1c13b; */
}
.boxText {
  text-align: left;
  font-size: 0.8rem;
  line-height: 1.8rem;
}
.boxText a {
  color: rgb(26, 25, 25);
}
span {
  font-size: 0.8rem;
  font-weight: bold;
}
.mainTitle {
  display: flex;
  align-self: center;
  margin-top: 0px;
  margin-bottom: 10px;
}

.section1 {
  display: flex;
  align-items: center;
}

.statusBox{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.8rem;
  min-width: 280px;
  gap: 10px;
}
.statusOpen {
  width: 10px;
  height: 10px;
  border-radius: 90px;
  border: 0.5px solid #000;
  background-color: rgb(242, 242, 20);
}
.statusPending {
  width: 10px;
  height: 10px;
  border-radius: 90px;
  border: 0.5px solid #000;
  background-color: rgb(226, 10, 10);
}
.statusClosed {
  width: 10px;
  height: 10px;
  border-radius: 90px;
  border: 0.5px solid #000;
  background-color: rgb(8, 226, 30);
}
.statusText{
  width: 60px;
}
.statusBox span{
  display: flex;
  width: 50px;
  justify-content: center;

}

/* .............................................. */
.container {
  max-width: 100%;
}

table {
  width: 100% !important;
  border-collapse: collapse;
  font-size: 0.8rem;
}

table th,
table td {
  padding: 8px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

table thead {
  background-color: #f5f1f1a6;
}

table tbody tr:nth-child(even) {
  background-color: #f9f9f93f;
}

table tbody tr:hover {
  background-color: #e4dfdf;
}

table td a {
  color: rgb(10, 10, 10);
  font-size: 0.8rem !important;
}
