 /* Seção Sobre */
 #about {
    display: flex;
    width: 100%;
    padding: 20px;
    /* margin: 0px 0px 0px 0px !important;  */
    margin: auto; 
    /* margin-top: 90px; */
    flex-direction: column;
    align-items: center;
    /* text-align: center; */
    text-align: justify;
    /* background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(203, 213, 223)); */
    font-size: 14px;
    font-weight: 700;
  }
  
  #about h1 {
    font-size: 28px;
    margin-bottom: 10px;
    font-family: var(--font-family-3);
    font-weight: italic;
  }
  
  #about p {
    font-size: 16px;
    margin-bottom: 20px;
    font-family: var(--font-family-2);
  }
  
  #about button {
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .about-box{
    margin-left: -20px !important;
    display: flex;
    width: 70%;
    min-height: 400px;
    height: auto;
    justify-content: space-around;
    /* background-color: rgba(255, 255, 0, 0.336); */
  }
  .aboutTitle{
    margin-top: 90px;
    font-family: var(--font-family-1);
    font-size: 3rem;
    color: #3a5b8b;
  }
  #about .col-4{
    display: flex;
    flex-direction: column;
    margin: 0px;
    align-content: center;
    padding: 25px;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.597);
  }
  .curved-line2 {
    margin-left: 0px;
    width: 100vw;
    height: 34px;
    background: linear-gradient(to left, transparent 10%, rgb(12, 87, 117) 50%, transparent 90%);
    background-size: 200% 100%;
    transform: scaleX(1) rotate(0deg);
    animation: moveLinearGradient 7s linear infinite;
    position: relative;
    z-index: 7;
  }
  @keyframes moveLinearGradient {
    0% {
      background-position: 100% 50%;
    }
    50% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }
  .col-4 ul li{
    margin-top: 25px;
  }

  .boxImg{
    width: 100%;
    height: 100%;
    opacity: 0.6;
  }