nav{
  overflow-x: hidden;
  width: 200px !important;
  height: 100vh !important;
  text-align: left;
  font-size: 0.8rem;
}

a{
  font-size: 0.9rem;
}

a#nav-dropdown.dropdown-toggle.nav-link{
  margin: 0px;
  width: 100%;
  padding: 2px;
  color: #353535;
  font-size: 0.9rem;
}

.alonelink{
  font-size:0.9rem;
  margin-left: 1px;  
}

.alonelink:hover{
  font-size:0.9rem;
 color: #353535;
}

span{
  font-size:0.9rem;
}

a#nav-dropdown.dropdown-toggle.nav-link{
  font-size: 0.9rem;
  margin-bottom: 10px ;
}

a#nav-dropdown.dropdown-toggle.show.nav-link{
  font-size: 0.9rem;
  padding: 1px;
}

a#nav-dropdown.dropdown-toggle.nav-link::after{
  font-size: 0.9rem;
visibility: hidden;
}
a.dropdown-item.active{
  font-size: 0.9rem;
  color:#353535;
  background-color: #a2a2a28d;
}

