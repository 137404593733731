*{
    margin: 0px; 
    padding: 0px;
    box-sizing: border-box;
}

  #mainBanner{
    width: 100vw;
    height: 100vh;
  }
  .sliderMainBanner .slick-slider{
    width: 100vw;
    height: 100vh;
  }
  
  .sliderMainBanner .slick-initialized{
    width: 100vw;
    height: 100vh;
    /* background-image: url(/img/businessHorizons.jpg); */
    background-size: cover;
    background-attachment: fixed;
  }
  
  .sliderMainBanner .slick-list{
    width: 100vw;
    height: 100vh;
    font-family: var(--font-family-2);
  }
  
  .sliderMainBanner .slideBan1{
    width: 100vw;
    height: 100vh;
    background-image: url(/img/postitboard.jpg);
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    z-index: 4;
  }
  #mainBanner .sliderMainBanner .slideBan2{  
    width: 100vw;
    height: 100vh;
    background-image: url(/img/copier.png);
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    z-index: 4;
  }
  #mainBanner .sliderMainBanner .slideBan3{  
    width: 100vw;
    height: 100vh;
    background-image: url(/img/teamboat.jpg);
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    z-index: 4;
  }
  .slideBan1 h3, .slideBan2 h3, .slideBan3 h3{
    display: flex;
    width: 100vw;
    height: 100vh;
    position: relative;
    z-index: 2;
    justify-content: center;
    align-items: center;
    margin-top: -150px;
    /* gap: 51px; */
    /* background-color: rgba(0, 140, 255, 0.205); */
    color: rgb(40, 51, 40);
    text-shadow: -1px -1px 0 #b7b7b9, 4px -1px 0 #51c5c7, -1px 3px 0 #565b5f, 1px 1px 0 #b52cc7; 
    font-size: 4rem;
    font-family: var(--font-family-1);
  }
  .slideBan3 h3{
    color: rgb(245, 247, 245);
  }
  
  .btnBan{
    position: relative;
    margin-top: -250px;
  }

  #mainBanner .sliderMainBanner .slidepicture{
    width: 100%;
    height: 100vh;
    position: relative;
    background-image: url(/img/copier.png);
    background-size: cover;
    background-attachment: fixed;
    z-index: 0;
    /* margin-top: -400px; */
    /* animation: slidepicture 45s ease-in-out infinite; */
  }
  @keyframes{
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.25);
    }
    100% {
      transform: scale(1.50);
    }
  }
  #mainBanner .sliderMainBanner .videoBan1{
    /* margin-top: 90px; */
    width: 100vw;
    height: 100vh;
    /* background-image: url('/img/teamworking.jpg'); */
    /* background-attachment: fixed; */
  }
  
  #mainBanner .sliderMainBanner .videoBan2{
    /* margin-top: 90px; */
    width: 100vw;
    height: 100vh;
    /* background-attachment: fixed; */
  }

  
  #mainBanner .sliderMainBanner .videoService{
    position: relative;
    width: 100vw;
    height: 100vh;
    /* margin-top: -400px; */
    z-index: 1;
    /* border-radius: 15px !important; */
  }