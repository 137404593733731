body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #fcf6f6;
  }
  
  /* Estilo da barra de navegação superior */
  .navbar-top {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background-color: #3b6769;
    color: #fff;
    padding: 10px;
  }
  
  /* Estilo da barra de navegação lateral */
  .navbar-side {
    position: fixed;
    top: 40px;
    left: 0;
    width: 5%;
    height: calc(100% - 180px);
    background-color: #eeeeee42;
    padding: 10px;
    z-index: 2;
  }
  
  /* Estilo dos itens da barra de navegação lateral */
  .navbar-side ul {
    list-style: none;
    padding: 0;
  }
  
  .navbar-side li {
    margin-bottom: 10px;
  }
  
  .navbar-side a {
    text-decoration: none;
    color: #333;
  }
  a{
    text-decoration: none;
    color: #333;
  }
  /* Estilo da página central */
  .content {
    margin-top: 80px;
    margin-left: 200px;
    width: 100%;
    min-height: 75vh;
    height: 1200px;
    padding: 20px;
  }
  
  /* Estilo do rodapé */
  .footer {
    display: flex;
    justify-content: space-around;
    background-color: #3b6769;
    color: #fff;
    padding: 10px;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px auto;
    z-index: 3;
  }
  
  /* Estilos responsivos */
  @media (max-width: 768px) {
    /* Reduz a largura da barra de navegação lateral para 100% em telas menores */
    .navbar-side {
      width: 100%;
      position: static;
      margin-bottom: 20px;
    }
    
    /* Move a margem da página central para o topo */
    .content {
      margin-left: 0;
    }
}